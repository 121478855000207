.root {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  display: block;
  height: 100%;
}

.noMaxWidth {
  max-width: none;
}
