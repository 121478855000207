.content {
  display: grid;
  grid-template-columns: 50% minmax(200px, 50%);
  gap: 120px;
}

.imageContainer {
  height: calc(80vh - 48px - 48px - 48px) !important;
  text-align: right;
  display: inline-block;
  position: sticky;
  top: 48px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
}

.word {
  background-color: var(--gray);
  border-radius: 4px;
  padding: 8px;
  display: block;
  cursor: pointer;
  height: 32px;
  box-sizing: border-box;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.grid {
  align-content: flex-start;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 8px;
  height: 100%;
  overflow-y: scroll;
}

.word:hover {
  background-color: #139eef;
  color: var(--white);
}

@media screen and (max-width: 600px) {
  .content {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .imageContainer {
    height: auto !important;
    text-align: right;
    display: block;
    width: 100%;
  }
}
