.root {
  padding-top: 48px;
  display: grid;
  grid-template-columns: 30% minmax(200px, 70%);
  gap: 120px;
}

.grid {
  max-height: 60vh;
  overflow-y: scroll;
}

.buttons {
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 48px;
}

.phone {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 130px;
}

.result {
  display: flex;
  gap: 16px;
  align-items: center;
}

.green {
  color: var(--green);
}
.red {
  color: var(--red);
}

.checkPhone {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1280px) {
  .root {
    gap: 48px;
  }
}

@media screen and (max-width: 640px) {
  .root {
    padding-top: 48px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;
  }

  .imageContainer {
    max-width: 70%;
    margin: 0 auto;
  }
}
