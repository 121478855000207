.tokenSection {
  background-color: #eeeeee;
  padding: 37px 0 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tokenForm {
  margin: 0 auto;
  width: 100%;
}

.tutorial {
  background-color: #dddddd;
  margin-bottom: 48px;
}

.distanceHint {
  text-align: center;
}
.selectContainer {
  margin: 0 auto;
  margin-bottom: 24px;
  text-align: center;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 12px;
  max-width: 420px;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  justify-content: center;
  gap: 8px;
  margin-bottom: 48px;
}

.tab {
  background-color: var(--gray);
  height: 56px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
}

.tab.active {
  border: 4px solid #1356a8;
  box-sizing: border-box;
  border-radius: 8px;
}

@media screen and (max-width: 600px) {
  .tab {
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  }
}

@media screen and (max-width: 375px) {
  .tabs {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
