.root {
  margin-top: 24px;
  margin-bottom: 24px;
}

.button {
  display: inline-block;
  margin-bottom: 10px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: center;
  background-color: var(--light-gray);
  padding: 30px;
  text-align: left;
  gap: 20px;
  border-radius: 8px;
}

.checkbox > div {
  background-color: var(--white);
}

.title {
  /* display: flex; */
  align-items: center;
  grid-template-columns: minmax(200px, 600px) auto;
  justify-content: flex-start;

  display: grid;
  gap: 4px;
}

@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: auto;
  }
  .root {
    text-align: center;
  }
}
