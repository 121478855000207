.root {
  width: 100%;
  background-color: var(--light-black);
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  color: #aaaaaa;
  font-weight: 500;
  padding: 24px;
  text-align: center;
  transition: 0.3s ease all;
  text-decoration: none;
}

.navItem:nth-child(odd) {
  background-color: #333333;
}

.navItem:hover,
.activeNavItem {
  background-color: var(--blue) !important;
  color: var(--white) !important;
}
