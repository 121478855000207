.root {
  padding-top: 48px;
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #111111;
  margin-bottom: 8px;
}

.reason {
  color: var(--red);
}

.images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
