.image {
  width: auto;
}

.root {
  width: auto;
}

.container {
  display: inline-block;
}
