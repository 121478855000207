.root {
  padding-top: 120px;
  background-color: #aaa;
  box-sizing: border-box;
  color: #fff;
  height: 100%;
}

.subtitle {
  margin-bottom: 48px;
}

.content {
  max-width: 760px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .root {
    height: 100vh;
  }
}
