.button {
  cursor: pointer;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #444444;
}

.content {
  padding-top: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.wrong,
.right {
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.wrong {
  border: 4px solid #c60c31;
}

.right {
  border: 4px solid #219653;
}

.examples {
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: minmax(140px, 160px) minmax(140px, 160px);
  grid-template-rows: 160px;
  gap: 16px;
}

.examples4 {
  display: grid;
  grid-template-columns: minmax(140px, 160px) minmax(140px, 160px);
  grid-template-rows: 72px 72px;
  gap: 16px;
  margin-bottom: 16px;
}

.subheading {
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 600px) {
  .button {
    line-height: 16px;
  }
}
