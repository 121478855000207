.root {
  padding-top: 48px;
}

.box {
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--black);
  background: #eeeeee;
  height: 64px;
  background-color: var(--light-gray);
  border-radius: 8px;
  justify-content: center;
}

.image {
  max-width: 100%;
}

.imageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 16px;
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #111111;
  margin-bottom: 8px;
}

.green,
.red {
  color: #fafafa;
}

.green {
  background-color: var(--green);
}
.red {
  background-color: var(--red);
}
