.box {
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--black);
  background: #eeeeee;
  height: 64px;
  background-color: var(--light-gray);
  border-radius: 8px;
  justify-content: center;
}

.green,
.red {
  color: #fafafa;
}

.green {
  background-color: var(--green);
}
.red {
  background-color: var(--red);
}

@media screen and (max-width: 600px) {
  .box {
    padding: 8px 16px;
  }
}
