.bbox {
  position: absolute;
}

.bbox:hover .confidence {
  display: block;
}

.root {
  position: relative;
}

.bbox {
  position: absolute;
}

.image {
  max-width: 100%;
}
