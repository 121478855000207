.root {
  font-family: 'TT Norms';
  font-feature-settings: 'liga' off;
}

.center {
  text-align: center;
}

.h1 {
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 96px;
  font-weight: 500;
}

.h2 {
  font-size: 48px;
  margin-bottom: 60px;
  line-height: 60px;
  font-weight: 500;
}

.h3 {
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 12px;
  font-weight: 500;
}

.h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  font-weight: 500;
}

.h5 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .h1 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .noMbMobile {
    margin-bottom: 0;
  }
}
