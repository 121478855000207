.hint {
  top: -18px;
  left: 0;
  color: green;
  white-space: nowrap;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  position: absolute;
  display: none;
  z-index: 10;
}

.polygon:hover + .hint {
  display: block;
}
