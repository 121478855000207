.root {
  max-width: 100%;
  /* height: calc(100vh - 48px - 48px - 48px - 48px); */
  display: flex;
  flex-direction: column;
  position: relative;
}

.extended {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 2px 0 6px;
  background-color: var(--light-gray);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
}

.extended:hover {
  opacity: 0.7;
}

.binary div:nth-child(2) {
  position: relative;
  top: 1px;
}

.header,
.gridRow {
  display: grid;
  grid-template-columns: 140px 1fr 120px;
  row-gap: 48px;
  align-items: center;
  column-gap: 24px;
  padding-right: 20px;
  text-overflow: ellipsis;
}

.confidenceHeader {
  display: flex;
  align-items: center;
}

.header {
  margin-bottom: 46px;
}

.gridRow:not(:last-child) {
  margin-bottom: 36px;
}

.grid {
  height: 100%;
  margin-bottom: 40px;
}

.gridHeaderItem {
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #aaaaaa;
}

.fieldName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fieldValue {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  max-width: 100%;
  text-overflow: ellipsis;
}

.mobileName {
  font-weight: 500;
  margin-bottom: 8px;
}

@media screen and (max-width: 1400px) {
  .header,
  .gridRow {
    grid-template-columns: 100px 1fr 120px;
  }
}

@media screen and (max-width: 1220px) {
  .header,
  .gridRow {
    grid-template-columns: 100px 120px auto;
  }
}

@media screen and (max-width: 600px) {
  .root {
    height: auto;
  }
  .grid {
    max-height: none;
  }
  .header,
  .gridRow {
    gap: 16px;
    grid-template-columns: 1fr 100px;
    padding-right: 0;
  }
}
