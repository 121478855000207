.root {
  padding-top: 168px;
}

.heading {
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 96px;
  color: #111111;
  margin-bottom: 24px;
  margin-top: 0;
}

.text {
  font-size: 24px;
  line-height: 36px;
  color: #111111;
  margin-bottom: 48px;
}

.download {
  color: #1356a8;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
}

@media screen and (max-width: 600px) {
  .root {
    padding-top: 120px;
  }
}
