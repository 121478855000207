.root {
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-template-rows: 48px 1fr;
  grid-template-areas:
    'header  header'
    'sidebar content';
  height: 100%;
  max-height: 100vh;
}

.content {
  /* max-height: calc(100vh - 48px); */
}

.header {
  grid-area: header;
  height: 48px;
}

.content {
  grid-area: content;
  height: 100%;
  overflow: hidden;
}

.sidebar {
  grid-area: sidebar;
}

@media screen and (max-width: 1280px) {
  .root {
    grid-template-columns: 280px 1fr;
  }
}

@media screen and (max-width: 660px) {
  .sidebar {
    position: absolute;
    top: 48px;
    width: 100%;
    z-index: 10;
  }
  .root {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
  }
}
