.root {
  padding-top: 48px;
  padding-bottom: 48px;
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #111111;
  margin-bottom: 8px;
}

.reason {
  color: var(--red);
}

.image {
}

.word {
  background-color: var(--gray);
  border-radius: 4px;
  padding: 8px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  border: 2px solid transparent;
}

.word:hover {
  border: 2px solid #c60c31;
}

.items {
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item {
}
