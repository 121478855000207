.root {
  height: 48px;
  background-color: var(--black);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0 24px;
  position: relative;
  overflow-y: hidden;
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-self: flex-start;
}

.content {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 4fr;
  width: 100%;
}

.home {
  background: transparent;
  border: none;
  color: var(--white);
  cursor: pointer;
  text-align: left;
}

.links {
  justify-self: flex-end;
  gap: 24px;
  display: flex;
}

.links a {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--white);
  align-items: center;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 660px) {
  .links {
    padding: 36px 24px;
  }
  .links a {
    display: block;
    text-align: center;
    margin-bottom: 24px;
    margin-right: 0;
  }
}

@media screen and (max-width: 660px) {
  .content {
    display: flex;
    justify-content: space-between;
  }
}
